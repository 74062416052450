<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <h3 class="mb-0 text-center">
            <v-select v-model="selected_employee" :options="employees" label="employee_name">
              <template #option="{ payments_data, employee_name }" style="height:20px">
                <feather-icon
                  v-if="payments_data.done && payments_data.done == true"
                  icon="CheckCircleIcon"
                  size="16"
                  class="text-success"
                />
                <feather-icon
                  v-else
                  icon="MinusCircleIcon"
                  size="16"
                  class="text-danger"
                />
                <span style="font-size:12pt" class="ml-50"> {{ employee_name }}</span>
              </template>

              <template #selected-option="{ payments_data, employee_name }">
                <feather-icon
                  v-if="payments_data.done && payments_data.done == true"
                  icon="CheckCircleIcon"
                  size="26"
                  class="text-success"
                />
                <feather-icon
                  v-else
                  icon="MinusCircleIcon"
                  size="26"
                  class="text-danger"
                />
                <span class="ml-50"> {{ employee_name }}</span>
              </template>

            </v-select>
          </h3>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card v-if="employee.id">
          <table class="table">
            <tr>
              <th>Dátum</th>
              <th>Nap</th>
              <th>Munkaidő kezdete</th>
              <th>Munkaidő vége</th>
              <th>Reggeli túlóra</th>
              <th>Éjszakai túlóra</th>
              <th>Ledolgozott óra</th>
              <th>Típus</th>
            </tr>
            <tr v-for="(day, index) in employee.days" :key="index">
              <td :class="{ 'table-warning' : day.day_name === 'Vasárnap', 'table-info' : day.day_name === 'Szombat'}">
                <span v-if="day.color == 'none'" class="badge" style="color:#b4b7bd">{{ day.date }}</span>
                <span v-else-if="day.color !== 'yellow'" class="badge" :style="'background:'+day.color">{{ day.date }}</span>
                <span v-else class="badge" :style="'background:'+day.color+';color:black'">{{ day.date }}</span>
              </td>
              <td :class="{ 'table-warning' : day.day_name === 'Vasárnap',  'table-info' : day.day_name === 'Szombat'}">
                <span v-if="day.day_name === 'Vasárnap'" class="text-danger">{{ day.day_name }}</span>
                <span v-else-if="day.day_name === 'Szombat'" class="text-info">{{ day.day_name }}</span>
                <span v-else>{{ day.day_name }}</span>
              </td>
              <td :class="{ 'table-warning text-danger' : day.day_name === 'Vasárnap', 'table-info text-info' : day.day_name === 'Szombat'}"><b-form-input :id="'start_time_'+index" :next-attr="index" @keydown="three_tab2" @change="effect = true" type="time" v-model="employee.days[index].start_time" /></td>
              <td :class="{ 'table-warning text-danger' : day.day_name === 'Vasárnap', 'table-info text-info' : day.day_name === 'Szombat'}"><b-form-input :id="'end_time_'+index" :next-attr="index" @keydown="three_tab" @change="effect = true" type="time" v-model="employee.days[index].end_time"/></td>
              <td :class="{ 'table-warning text-danger' : day.day_name === 'Vasárnap', 'table-info text-info' : day.day_name === 'Szombat'}">{{ employee.days[index].start_time_extra }}</td>
              <td :class="{ 'table-warning text-danger' : day.day_name === 'Vasárnap', 'table-info text-info' : day.day_name === 'Szombat'}">{{ employee.days[index].end_time_extra }}</td>
              <td :class="{ 'table-warning text-danger' : day.day_name === 'Vasárnap', 'table-info text-info' : day.day_name === 'Szombat'}">{{ day.worked_hours }}  <span v-if="employee.days[index].start_time_extra != '00:00'">+{{ employee.days[index].start_time_extra }}</span> <span v-if="employee.days[index].end_time_extra != '00:00'">+{{ employee.days[index].end_time_extra }}</span></td>
              <td :class="{ 'table-warning text-danger' : day.day_name === 'Vasárnap', 'table-info text-info' : day.day_name === 'Szombat'}">
                <span v-if="day.color !== 'yellow'" class="badge" :style="'background:'+day.color">{{ employee.days[index].type }}</span>
                <span v-else class="badge" :style="'background:'+day.color+';color:black'">{{ employee.days[index].type }}</span>
              </td>
            </tr>
          </table>
          <div class="row mt-3">
            <div class="col-lg-2">
              <b-form-group
                label="Óra bér"
                v-if="this.employee.payments_data.group_number == 1"
                label-for="hour_price"
              >

                <b-form-input
                  id="hour_price"
                  style="max-width: 200px;"
                  v-model="employee.payments_data.hour_price"
                  :class="{'text-danger border-danger': employee.payments_data.hour_price == 0}"
                  type="number"
                  placeholder="Óra bér"
                />
              </b-form-group>
              <b-form-group
                label="Havi bér"
                v-else
                label-for="hour_price"
              >

                <b-form-input
                  id="hour_price"
                  style="max-width: 200px;"
                  v-model="employee.payments_data.hour_price"
                  type="number"
                  placeholder="Havi bér"
                />
              </b-form-group>
            </div>
            <div class="col-lg-10 text-right">
              <br>
              <button class="btn btn-primary mr-2" @click="$bvModal.show('hour_copy')">Órák másolása</button>
              <button class="btn btn-primary mr-2" :class="{'btn-danger': effect}" @click="reCalculateHours">Számolás</button>
              <button class="btn btn-primary" @click="setDone">Kész</button>
              <!--<button class="btn btn-primary ml-2" @click="$bvModal.show('import_modal_121')">121 importálása</button>
              <button class="btn btn-primary ml-2" @click="$bvModal.show('import_modal_salary')">Bér importálása</button>
              <button class="btn btn-primary ml-2" @click="$bvModal.show('import_modal')">Hónap importálása</button>
              <button class="btn btn-primary ml-2" @click="export_month">Exportálás</button>
              <button class="btn btn-primary ml-2" @click="export_pdf">PDF generálás</button>-->
            </div>
          </div>
        </b-card>
        <b-card v-else>
          <h1 class="text-center pt-5 pb-5">Válassz ki egy alkalmazottat</h1>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card v-if="employee.id">
          <h3 class="text-center mb-2">Szabadság</h3>
          <table class="table">
            <tr>
              <td>
                {{ employee.start_date.substring(0, 4) }} Alap Szabadság:
              </td>
              <td>
                {{ employee.payments_data.free_day_all_year }} Nap
              </td>
            </tr>
            <tr>
              <td>
                Szabadság összege/nap:
              </td>
              <td>
                {{ parseInt(employee.payments_data.free_day_salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Eddig kivett szabadság:
              </td>
              <td>
                {{ employee.payments_data.last_month_free_day }} nap
              </td>
            </tr>
            <tr>
              <td>
                Most igényelt szabadság:
              </td>
              <td>
                {{ employee.holiday }} nap
              </td>
            </tr>
            <tr>
              <td>
                Összes igényelt szabadság
              </td>
              <td>
                {{ employee.payments_data.total_used_free_day }} nap
              </td>
            </tr>
            <tr>
              <td>
                Eddigi igazolatlan napok:
              </td>
              <td>
                {{ employee.payments_data.last_unverified }} nap
              </td>
            </tr>
            <tr>
              <td>
                Mostani igazolatlan napok
              </td>
              <td>
                {{ employee.payments_data.current_unverified }} nap
              </td>
            </tr>
            <tr>
              <td>
                Összes igazolatlan nap
              </td>
              <td>
                {{ employee.payments_data.total_unverified }} nap
              </td>
            </tr>
            <tr>
              <td>
                Fizetett Ünnep:
              </td>
              <td>
                {{ employee.global_freeday }} nap
              </td>
            </tr>
            <tr>
              <td>
                Fizetetlen nap:
              </td>
              <td>
                {{ employee.payments_data.current_unpaid }} nap
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card v-if="employee.id != null">
          <h3 class="text-center mb-2">Táppénz</h3>
          <table class="table">
            <tr>
              <td>
                {{ employee.start_date.substring(0, 4) }} Alap Táppénz:
              </td>
              <td>
                {{ employee.payments_data.sick_day_all_year }} Nap
              </td>
            </tr>
            <tr>
              <td>
                Táppénz összege/nap:
              </td>
              <td>
                {{ parseInt(employee.payments_data.sick_day_salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Eddig kivett munkáltatói táppénz:
              </td>
              <td>
                {{ employee.payments_data.last_month_sick_day }} nap
              </td>
            </tr>
            <tr>
              <td>
                Most igényelt munkáltatói táppénz:
              </td>
              <td>
                {{ employee.sick_pay }} nap
              </td>
            </tr>
            <tr>
              <td>
                Összes igényelt munkáltatói táppénz
              </td>
              <td>
                {{ employee.payments_data.total_used_sick_day }} nap
              </td>
            </tr>
            <tr>
              <td>
                Eddig kivett OEP táppénz
              </td>
              <td>
                {{ employee.payments_data.osh_last }} nap
              </td>
            </tr>
            <tr>
              <td>
                Most igényelt OEP táppénz:
              </td>
              <td>
                {{ employee.payments_data.osh_current }} nap
              </td>
            </tr>
            <tr>
              <td>
                Összes igényelt OEP táppénz:
              </td>
              <td>
                {{ employee.payments_data.osh_total }} nap
              </td>
            </tr>
            <tr>
              <td>
                Összes igénybevett táppénz
              </td>
              <td>
                {{ employee.payments_data.sick_day_unpaid_count_total_in_this_year }} nap
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card v-if="employee.id != null">
          <h3 class="text-center mb-2">Fizetés</h3>
          <table class="table">
            <tr>
              <td>
                Összes óra:
              </td>
              <td>
                {{ employee.payments_data.worked_hours_total }} Óra
              </td>
            </tr>
            <tr>
              <td>
                Fizetés
              </td>
              <td>
                {{ parseInt(employee.payments_data.salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Gyerek kedv.:
              </td>
              <td>
                <input type="text" class="form-control" v-model="employee.payments_data.children_money">
                <!--{{ parseInt(employee.payments_data.children_money).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft-->
              </td>
            </tr>
            <tr v-if="this.employee.payments_data.group_number == 1">
              <td>
                Fizetett szabadság:
              </td>
              <td>
                {{ parseInt(employee.payments_data.current_month_free_day_salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr v-if="this.employee.payments_data.group_number == 1">
              <td>
                Fizetett ünnep:
              </td>
              <td>
                {{ parseInt(employee.payments_data.global_freeday_salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Táppénz:
              </td>
              <td>
                {{ parseInt(employee.payments_data.current_month_sick_day_salary).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Utazási támogatás:
              </td>
              <td>
                <input type="text" class="form-control" v-model="employee.payments_data.travel_support">
              </td>
            </tr>
            <tr v-for="extra in employee.payments_data.extra" :key="extra.title">
              <td>
                {{ extra.title }}
              </td>
              <td>
                {{ parseInt(extra.price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Átutalás:
              </td>
              <td>
                - {{ parseInt(employee.payments_data.transfer).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Hitel:
              </td>
              <td>
                - {{ parseInt(employee.payments_data.debt).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Hitel kamata:
              </td>
              <td>
                - {{ parseInt(employee.payments_data.debt_interest).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Letiltás:
              </td>
              <td>
                - {{ parseInt(employee.payments_data.block).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
            <tr>
              <td>
                Fizetés összesen:
              </td>
              <td>
                {{ parseInt(employee.payments_data.salary_total).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <button class="btn btn-primary w-100" @click="$bvModal.show('import_modal_121')">121 importálása</button>
          <button class="btn btn-primary w-100 mt-2" @click="$bvModal.show('import_modal_salary')">Bér importálása</button>
          <button class="btn btn-primary w-100 mt-2" @click="$bvModal.show('import_modal')">Hónap importálása</button>
          <button class="btn btn-primary w-100 mt-2" @click="export_month">Exportálás</button>
          <button class="btn btn-primary w-100 mt-2" @click="export_pdf">PDF generálás</button>
          <button class="btn btn-primary w-100 mt-2" @click="refresh2Time()">Szabadságok, levonsások újra generálása</button>
          <button class="btn btn-primary w-100 mt-2" @click="clear_121">121-es fájl törlése</button>
          <button class="btn btn-danger w-100 mt-2" @click="reloadData">Adatok újra generálása</button>
          <!--<button class="btn btn-danger w-100 mt-2" @click="$bvModal.show('lock_modal')">Adatok lezárása</button>-->
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="lock_modal" title="Adatok lezárása" size="sm" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <p>Lezárás dátuma</p>
        <b-form-input
          v-model="lock_date"
          type="date" max="9999-12-31"
        />
        <p class="text-right mt-2 w-100">
          <button class="btn btn-primary" @click="lockDatas">Lezárás</button>
        </p>
      </b-row>
    </b-modal>
    <b-modal id="import_modal" title="Hónap importálása" size="sm" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-form-file
          @change="import_month"
          accept=".monolit"
          label="Hónap importálása"
        />
      </b-row>
    </b-modal>
    <b-modal id="import_modal_121" title="121-es fájl importálása" size="sm" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-form-file
          @change="import_121"
          accept=".121"
          label="121-es fájl importálása"
        />
      </b-row>
    </b-modal>
    <b-modal id="import_modal_salary" title="Bérek importálása" size="sm" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-form-file
          @change="import_month_salary"
          accept=".monolit"
          label="Bérek importálása"
        />
      </b-row>
    </b-modal>
    <b-modal id="hour_copy" title="Órák másolása" size="sm" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <small class="mb-2">Órák másolásakor kizárólag azokat az órákat van lehetőség másolni mely napok nem speciálisak (hamarabb elment, szabadnapon....)</small>
        <p class="mb-0">Válaszd ki, hogy {{ employee.employee_name }} óraszáma kikkel azonos</p>
        <b-form-select
          multiple
          v-model="selected_hour_copy_members"
          class="mt-1"
          :options="employees"
          text-field="employee_name"
          value-field="employee_id"
          placeholder="Válassz egy dolgozót"
          style="height:400px"
        />
        <small class="mt-1">Ctrl + katt több kijelöléséhez</small>
        <p class="text-center col-lg-12">
          <button class="btn btn-primary mt-2" @click="copyHours">
            Másolás
          </button>
        </p>
      </b-row>
    </b-modal>
    <loading :status="status" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Loading from '@/components/loading/Loading.vue'
import { forEach } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    BFormFile,
    BFormSelect,
    BCard,
    BRow,
    BFormInput,
    BCol,
    BFormGroup,
    Loading,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      employees: [],
      selected_hour_copy_members: [],
      selected_employee: null,
      employee: {
        created_at: null,
        days: [],
        employee_id: null,
        empolyee_name: null,
        end_date: null,
        import_month_salary: null,
        global_freeday: 0,
        holiday: 0,
        id: null,
        payments_data: [],
        sick_pay: 0,
        start_date: null,
        updated_at: null,
        year: null,
      },
      months: [],
      file: null,
      effect: false,
      status: false,
      lock_date: new Date().toISOString().substr(0, 10),
    }
  },
  mounted() {
    this.reloadData()
  },
  watch: {
    // eslint-disable-next-line camelcase
    selected_employee(new_value) {
      // eslint-disable-next-line prefer-destructuring, camelcase
      this.employee = this.employees.filter(employee => employee.id === new_value.id)[0]
    },
  },
  methods: {
    setDone() {
      this.selected_employee.payments_data.done = true

      this.employees = this.employees.map(employee => {
        if (employee.id === this.selected_employee.id) {
          employee.payments_data.done = true
        }
        return employee
      })
    },
    three_tab(event) {
      const index = event.target.getAttribute('next-attr')
      if (event.key === 'a') {
        document.getElementById(`end_time_${parseInt(index)+1}`).focus()
      }
    },
    three_tab2(event) {
      const index = event.target.getAttribute('next-attr')
      if (event.key === 'a') {
        document.getElementById(`start_time_${parseInt(index)+1}`).focus()
      }
    },
    lockDatas() {
      axios({
        method: 'post',
        url: 'lock-data',
        data: {
          lock_date: this.lock_date,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Lezárás sikeresen megtörtént',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$bvModal.hide('lock_modal')
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add meg a dátumot',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    convertHourToSec(timeString) {
      const [hours, minutes] = timeString.split(':')
      return (hours * 60 * 60) + (minutes * 60)
    },
    convertSecToHour(seconds) {
      return (seconds / 60 / 60).toFixed(2)
    },

    reCalculateHours() {
      axios({
        method: 'post',
        url: 'accounting/re-count-hours',
        data: {
          employees: this.employees,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.employees = response.data

        // sum employee days
        this.employees.forEach((employee, index) => {
          // eslint-disable-next-line camelcase
          let worked_hours_total = 0
          employee.days.forEach(day => {
            if (day.worked_hours) {
              // eslint-disable-next-line camelcase
              worked_hours_total += this.convertHourToSec(day.worked_hours)
              // eslint-disable-next-line camelcase
              worked_hours_total += this.convertHourToSec(day.start_time_extra)
              // eslint-disable-next-line camelcase
              worked_hours_total += this.convertHourToSec(day.end_time_extra)
            }
          })
          this.employees[index].payments_data.worked_hours_total = this.convertSecToHour(worked_hours_total)
          if (this.employees[index].payments_data.transfer == null) {
            this.employees[index].payments_data.transfer = 0
          }
          if (this.employees[index].payments_data.debt == null) {
            this.employees[index].payments_data.debt = 0
          }

          if (this.employees[index].payments_data.block == null) {
            this.employees[index].payments_data.block = 0
          }

          if (this.employees[index].payments_data.hour_price == null) {
            this.employees[index].payments_data.hour_price = 0
          }
          if (this.employees[index].payments_data.group_number == 1) {
            this.employees[index].payments_data.salary = (this.employees[index].payments_data.hour_price * (this.employees[index].payments_data.worked_hours_total))
          } else {
            this.employees[index].payments_data.salary = this.employees[index].payments_data.hour_price
          }

          let total_extra_money = 0
          if (this.employees[index].payments_data.extra) {
            this.employees[index].payments_data.extra.forEach(extra => {
              total_extra_money += parseInt(extra.price)
            })
          }
          
          if (this.employees[index].payments_data.group_number == 1) {
            this.employees[index].payments_data.salary_total = (this.employees[index].payments_data.hour_price * (this.employees[index].payments_data.worked_hours_total)) + parseInt(this.employees[index].payments_data.children_money) + parseInt(this.employees[index].payments_data.global_freeday_salary) + parseInt(this.employees[index].payments_data.current_month_free_day_salary) + parseInt(this.employees[index].payments_data.current_month_sick_day_salary) - parseInt(this.employees[index].payments_data.transfer) - parseInt(this.employees[index].payments_data.debt) + parseInt(total_extra_money) + parseInt(this.employees[index].payments_data.travel_support) - parseInt(this.employees[index].payments_data.block) - parseInt(this.employees[index].payments_data.debt_interest)
          } else {
            this.employees[index].payments_data.salary_total = (parseInt(this.employees[index].payments_data.hour_price) * (1)) + parseInt(this.employees[index].payments_data.children_money) /* + this.employees[index].payments_data.global_freeday_salary + this.employees[index].payments_data.current_month_free_day_salary */ + this.employees[index].payments_data.current_month_sick_day_salary - this.employees[index].payments_data.transfer - this.employees[index].payments_data.debt + parseInt(total_extra_money) + parseInt(this.employees[index].payments_data.travel_support) - parseInt(this.employees[index].payments_data.block) - parseInt(this.employees[index].payments_data.debt_interest)
          }
          console.log(this.employees[index].payments_data.salary_total)
          this.employees[index].payments_data.salary_total = Math.round(this.employees[index].payments_data.salary_total / 50) * 50

          if (this.employee.employee_id == this.employees[index].employee_id) {
            this.employee = this.employees[index]
          }
        })

        this.effect = false
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nincs találat',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },

    refresh2Time() {
      this.refreshData()
    },

    copyHours() {
      // eslint-disable-next-line no-plusplus
      this.reCalculateHours()

      for (let i = 0; i < this.selected_hour_copy_members.length; i++) {
        const employee_data = this.employees.find(employee => employee.employee_id === this.selected_hour_copy_members[i])

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < employee_data.days.length; index++) {
          if (employee_data.days[index].type === null && this.employee.days[index].type === null) {
            employee_data.days[index].start_time = this.employee.days[index].start_time
            employee_data.days[index].end_time = this.employee.days[index].end_time
            employee_data.days[index].start_time_extra = this.employee.days[index].start_time_extra
            employee_data.days[index].end_time_extra = this.employee.days[index].end_time_extra
            employee_data.days[index].worked_hours = this.employee.days[index].worked_hours
          }
        }

        this.employees.forEach((employee, index) => {
          if (employee.employee_id === employee_data.employee_id) {
            this.employees[index] = employee_data
          }
        })
      }
      this.reCalculateHours()

      this.selected_hour_copy_members = []
      this.$bvModal.hide('hour_copy')

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sikeresen átmásoltad az órákat',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    refreshData() {
      this.status = true
      axios({
        method: 'post',
        url: 'accounting/calculate-employees',
        data: {
          employees: this.employees,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.employees = response.data

        // sum employee days
        this.employees.forEach((employee, index) => {
          // eslint-disable-next-line camelcase
          let worked_hours_total = 0
          employee.days.forEach(day => {
            if (day.worked_hours) {
              // eslint-disable-next-line camelcase
              worked_hours_total += this.convertHourToSec(day.worked_hours)
              // eslint-disable-next-line camelcase
              worked_hours_total += this.convertHourToSec(day.start_time_extra)
              // eslint-disable-next-line camelcase
              worked_hours_total += this.convertHourToSec(day.end_time_extra)
            }
          })
          this.employees[index].payments_data.worked_hours_total = this.convertSecToHour(worked_hours_total)
          if (this.employees[index].payments_data.transfer == null) {
            this.employees[index].payments_data.transfer = 0
          }
          if (this.employees[index].payments_data.debt == null) {
            this.employees[index].payments_data.debt = 0
          }

          if (this.employees[index].payments_data.block == null) {
            this.employees[index].payments_data.block = 0
          }

          if (this.employees[index].payments_data.hour_price == null) {
            this.employees[index].payments_data.hour_price = 0
          }
          if (this.employees[index].payments_data.group_number == 1) {
            this.employees[index].payments_data.salary = (this.employees[index].payments_data.hour_price * (this.employees[index].payments_data.worked_hours_total))
          } else {
            this.employees[index].payments_data.salary = this.employees[index].payments_data.hour_price
          }

          let total_extra_money = 0
          if (this.employees[index].payments_data.extra) {
            this.employees[index].payments_data.extra.forEach(extra => {
              total_extra_money += parseInt(extra.price)
            })
          }

          if (this.employees[index].payments_data.group_number == 1) {
            this.employees[index].payments_data.salary_total = (this.employees[index].payments_data.hour_price * (this.employees[index].payments_data.worked_hours_total)) + parseInt(this.employees[index].payments_data.children_money) + this.employees[index].payments_data.global_freeday_salary + this.employees[index].payments_data.current_month_free_day_salary + this.employees[index].payments_data.current_month_sick_day_salary - this.employees[index].payments_data.transfer - this.employees[index].payments_data.debt + parseInt(total_extra_money) + parseInt(this.employees[index].payments_data.travel_support) - parseInt(this.employees[index].payments_data.block)
          } else {
            this.employees[index].payments_data.salary_total = (this.employees[index].payments_data.hour_price * (1)) + parseInt(this.employees[index].payments_data.children_money) /* + this.employees[index].payments_data.global_freeday_salary + this.employees[index].payments_data.current_month_free_day_salary */ + this.employees[index].payments_data.current_month_sick_day_salary - this.employees[index].payments_data.transfer - this.employees[index].payments_data.debt + parseInt(total_extra_money) + parseInt(this.employees[index].payments_data.travel_support) - parseInt(this.employees[index].payments_data.block)
          }
          this.employees[index].payments_data.salary_total = Math.round(this.employees[index].payments_data.salary_total / 50) * 50
        })

        this.effect = false
        axios({
          method: 'post',
          url: 'accounting/calculate-employees',
          data: {
            employees: this.employees,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).then(response => {
          this.employees = response.data

          // sum employee days
          this.employees.forEach((employee, index) => {
            // eslint-disable-next-line camelcase
            let worked_hours_total = 0
            employee.days.forEach(day => {
              if (day.worked_hours) {
                // eslint-disable-next-line camelcase
                worked_hours_total += this.convertHourToSec(day.worked_hours)
                // eslint-disable-next-line camelcase
                worked_hours_total += this.convertHourToSec(day.start_time_extra)
                // eslint-disable-next-line camelcase
                worked_hours_total += this.convertHourToSec(day.end_time_extra)
              }
            })
            this.employees[index].payments_data.worked_hours_total = this.convertSecToHour(worked_hours_total)
            if (this.employees[index].payments_data.transfer == null) {
              this.employees[index].payments_data.transfer = 0
            }
            if (this.employees[index].payments_data.debt == null) {
              this.employees[index].payments_data.debt = 0
            }

            if (this.employees[index].payments_data.block == null) {
              this.employees[index].payments_data.block = 0
            }

            if (this.employees[index].payments_data.hour_price == null) {
              this.employees[index].payments_data.hour_price = 0
            }
            if (this.employees[index].payments_data.group_number == 1) {
              this.employees[index].payments_data.salary = (this.employees[index].payments_data.hour_price * (this.employees[index].payments_data.worked_hours_total))
            } else {
              this.employees[index].payments_data.salary = this.employees[index].payments_data.hour_price
            }

            let total_extra_money = 0
            if (this.employees[index].payments_data.extra) {
              this.employees[index].payments_data.extra.forEach(extra => {
                total_extra_money += parseInt(extra.price)
              })
            }

            if (this.employees[index].payments_data.group_number == 1) {
              this.employees[index].payments_data.salary_total = (this.employees[index].payments_data.hour_price * (this.employees[index].payments_data.worked_hours_total)) + parseInt(this.employees[index].payments_data.children_money) + this.employees[index].payments_data.global_freeday_salary + this.employees[index].payments_data.current_month_free_day_salary + this.employees[index].payments_data.current_month_sick_day_salary - this.employees[index].payments_data.transfer - this.employees[index].payments_data.debt + parseInt(total_extra_money) + parseInt(this.employees[index].payments_data.travel_support) - parseInt(this.employees[index].payments_data.block) - parseInt(this.employees[index].payments_data.debt_interest)
            } else {
              this.employees[index].payments_data.salary_total = (this.employees[index].payments_data.hour_price * (1)) + parseInt(this.employees[index].payments_data.children_money) /* + this.employees[index].payments_data.global_freeday_salary + this.employees[index].payments_data.current_month_free_day_salary */ + this.employees[index].payments_data.current_month_sick_day_salary - this.employees[index].payments_data.transfer - this.employees[index].payments_data.debt + parseInt(total_extra_money) + parseInt(this.employees[index].payments_data.travel_support) - parseInt(this.employees[index].payments_data.block) - parseInt(this.employees[index].payments_data.debt_interest)
            }
            this.employees[index].payments_data.salary_total = Math.round(this.employees[index].payments_data.salary_total / 50) * 50

            if (this.employee.employee_id == this.employees[index].employee_id) {
              this.employee = this.employees[index]
            }
          })

          this.effect = false
          this.status = false
        }).catch(() => {
          this.status = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nincs találat',
              icon: 'XOctagonIcon',
              variant: 'danger',
            },
          })
        })
      }).catch(() => {
        this.status = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nincs találat',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },

    getEmployees() {
      axios({
        method: 'get',
        url: `accounting/list-employees/${this.$route.params.date}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.employees = response.data

        if (this.employee == null) {
          this.employee = this.employees[0]
        }

        this.reCalculateHours()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nincs találat',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    readFile(file) {
      return new Promise(resolve => {
        const fr = new FileReader()
        fr.onload = () => resolve(fr.result)
        fr.readAsText(file)
      }).then(result => JSON.parse(result))
    },

    async import_month(e) {
      const file = e.target.files[0]

      this.employees = await this.readFile(file)

      // eslint-disable-next-line prefer-destructuring
      // this.employee = this.employees.filter(employee => employee.employee_id === this.employee.employee_id)[0]

      this.reCalculateHours()

      this.$bvModal.hide('import_modal')
    },

    async import_month_salary(e) {
      const file = e.target.files[0]

      const employee_data = await this.readFile(file)

      this.employees.forEach((employee, index) => {
        employee_data.forEach(element => {
          if (element.employee_id === employee.employee_id) {
            this.employees[index].payments_data.hour_price = element.payments_data.hour_price
          }
        })
        // employee.payments_data.hour_price = employee_data.filter(employee_data => employee_data.employee_id === employee.employee_id)[0].payments_data.hour_price
      })

      // this.employee = this.employees.filter(employee => employee.employee_id === this.employee.employee_id)[0]

      this.reCalculateHours()

      this.$bvModal.hide('import_modal_salary')
    },

    async readFileLineByLine(file) {
      return new Promise(resolve => {
        const fr = new FileReader()
        fr.onload = () => resolve(fr.result)
        fr.readAsText(file)
      }).then(result => {
        const lines = result.split('\n')
        const result1 = []

        for (let i = 1; i < lines.length - 1; i += 1) {
          let type = 'Átutalás'

          if (lines[i].indexOf('letilt') !== -1) {
            type = 'Letiltás'
          }

          const obj = {
            iban: lines[i].substring(26, 50),
            money: lines[i].substring(16, 26),
            name: lines[i].substring(146, 180),
            info: lines[i].substring(181, 251),
            type,
          }

          result1.push(obj)
        }

        return result1
      })
    },

    clear_121() {
      this.employees.forEach((employee, index) => {
        this.employees[index].payments_data.transfer = 0
        this.employees[index].payments_data.block = 0
      })

      this.reCalculateHours()
    },

    async import_121(e) {
      const file = e.target.files[0]

      // eslint-disable-next-line camelcase
      const payments_info = await this.readFileLineByLine(file)

      payments_info.forEach(payments_info => {
        const index = this.employees.findIndex(employee => employee.payments_data.iban === payments_info.iban)

        if (index !== -1) {
          if (payments_info.type === 'Átutalás') {
            this.employees[index].payments_data.transfer += parseInt(payments_info.money)
          } else {
            this.employees[index].payments_data.block += parseInt(payments_info.money)
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (payments_info.info != null || payments_info.name != null) {
            axios({
              method: 'post',
              url: 'private_comment/create/121',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
              data: {
                title: `${payments_info.info} (${payments_info.name})`,
                price: parseInt(`-${payments_info.money}`),
                selected_date: this.$route.params.date,
                shortTitle: payments_info.type,
              },
            })
          }
        }
      })

      this.reCalculateHours()

      this.$bvModal.hide('import_modal_121')
    },

    export_month() {
      const jsonStr = JSON.stringify(this.employees)
      const element = document.createElement('a')
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(jsonStr)}`)
      element.setAttribute('download', `${this.employee.start_date}-${this.employee.end_date}.monolit`)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },
    reloadData() {
      axios({
        method: 'post',
        url: `accounting/generate/${this.$route.params.date.slice(0, -3)}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getEmployees()
      })
    },
    export_pdf() {
      this.status = true
      axios({
        method: 'post',
        url: `accounting/generate-pdf/${this.$route.params.date}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          employee: this.employees,
        },
        responseType: 'blob',
      }).then(res => {
        this.status = false
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', `${this.$route.params.date} - jelenléti ív.pdf`)
        document.body.appendChild(link)
        link.click()
      }).catch(() => {
        this.status = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ismeretlen hiba történt 😢',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
